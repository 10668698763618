import { Col, Row } from 'react-flexbox-grid';
import MatchMap from './MatchMap';
import { Match, MatchState } from '../../lib/actions/MatchAction';
import MatchPanel from './MatchPanel';
import { getActiveEta, matchInState } from '../../lib/Utility';
import { Card, Icon } from '@blueprintjs/core';
import { useMemo } from 'react';
import moment from 'moment';
import MatchActions from './MatchActions';

type MatchPanelProps = {
  match: Match;
};

export default function MatchView({ match }: MatchPanelProps) {
  const eta = useMemo(() => getActiveEta(match), [match]);

  const isMatchEnRoute = () => {
    if (!match.stops) return false;

    return matchInState(match, [
      MatchState.PickedUp,
      MatchState.ArrivedAtPickup,
      MatchState.EnRouteToPickup,
    ]);
  };

  return (
    <Row>
      <Col xs={12} lg={4} className='statusPane'>
        <MatchPanel match={match} />
      </Col>

      <Col xs={12} lg={8}>
        {eta && eta.arrive_at && (
          <Card className='flat-card'>
            <strong className='u-pad__right--sm u-text--primary'>ETA</strong>
            <span className='u-pad__right--sm'>
              <Icon
                icon='time'
                size={16}
                style={{ top: -1, position: 'relative' }}
                className='u-text--primary'
              />
              <span> {moment(eta.arrive_at).fromNow()}</span>
            </span>
            {eta.distance && (
              <span>
                <Icon
                  icon='route'
                  size={16}
                  style={{ top: -2, position: 'relative' }}
                  className='u-text--primary'
                />
                {eta.distance && <span> {eta.distance} mi away</span>}
              </span>
            )}
          </Card>
        )}
        <MatchMap match={match} isEnRoute={isMatchEnRoute()} />
        <MatchActions match={match} />
      </Col>
    </Row>
  );
}
