import { AnchorButton, Button, Callout, Intent } from '@blueprintjs/core';
import {
  Popover2,
  Popover2InteractionKind,
  Classes,
} from '@blueprintjs/popover2';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import {
  selectEstimateErrors,
  selectEstimateMatch,
  selectEstimateStatus,
} from '../../lib/reducers/estimateSlice';
import ErrorCallout from '../ErrorCallout';
import FormErrors from '../form/FormErrors';
import { isMatchEstimate } from '../../lib/Utility';

type StepFooterProps = {
  children?: React.ReactNode;
  onNext?: () => void;
  onPrev?: () => void;
  nextLabel?: string | ((isTouched: boolean) => string | undefined);
  hideRequestError?: boolean;
  isForm?: boolean;
  error?: string | null;
};

const defaultNextLabel = 'Next Step';

export default function StepFooter({
  children,
  onNext,
  onPrev,
  error,
  hideRequestError,
  isForm = true,
  nextLabel,
}: StepFooterProps) {
  const { isValid, isSubmitting, submitForm, values, initialValues } =
    useFormikContext() ||
    (isForm ? undefined : { isValid: true, isValidating: false });
  const status = useSelector(selectEstimateStatus);
  const { level: errorLevel, message: errorMessage } =
    useSelector(selectEstimateErrors);
  const isLoading = status === 'loading';
  const [attemptNext, setAttemptNext] = useState(false);
  const isTouched = values !== initialValues;
  const handleNext = async () => {
    if (isForm) {
      if (isTouched || status === 'failed') await submitForm();
    }
    setAttemptNext(true);
  };

  useEffect(() => {
    if (attemptNext && status !== 'loading') {
      if (status === 'succeeded') {
        onNext?.();
        window.scrollTo(0, 0);
      }
      setAttemptNext(false);
    }
  }, [attemptNext, status, onNext]);

  const match = useSelector(selectEstimateMatch);
  const isEstimate = isMatchEstimate(match);
  const hasPendingEdits = !isEstimate && isTouched;

  return (
    <>
      <div className='panelDivider' />
      <Row className='step-footer'>
        {hasPendingEdits && (
          <Col xs={12}>
            <Callout className='u-push__bottom--lg' intent={Intent.PRIMARY}>
              These changes may affect the amount you are charged, and will be
              immediately visible to the driver on save.
            </Callout>
          </Col>
        )}
        <Col xs={12} lg={onNext ? 7 : 12} xl={onNext ? 6 : 12}>
          {!hideRequestError && !isLoading && (
            <ErrorCallout
              title={
                errorLevel === 'danger'
                  ? 'We encountered a problem'
                  : errorMessage
              }
              message={
                errorLevel === 'danger'
                  ? errorMessage
                  : 'Please update your order details to continue'
              }
              level={errorLevel}
            />
          )}
          {children}
          {onPrev && (
            <Button
              onClick={onPrev}
              disabled={isLoading}
              icon='chevron-left'
              className='u-float--left minimalButton'
              minimal
              large
            >
              Back
            </Button>
          )}
        </Col>
        {onNext && (
          <Col xs={12} lg={5} xl={6}>
            <Popover2
              interactionKind={Popover2InteractionKind.CLICK}
              className='u-float--right u-width__full u-width__auto--lg u-push__bottom--sm'
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              disabled={isValid && !error} // Don't show popover when continuing to next screen is permitted
              content={
                <div>
                  <h3 className='u-push__top--none u-push__bottom--md'>
                    Some issues need corrected
                  </h3>
                  <div>
                    {error ? <p className='error'>{error}</p> : <FormErrors />}
                  </div>
                  <Button className={Classes.POPOVER2_DISMISS}>
                    Understood
                  </Button>
                </div>
              }
            >
              <AnchorButton
                loading={isLoading}
                disabled={!isValid || !!error || isLoading || isSubmitting}
                rightIcon='chevron-right'
                className='secondaryButtonFilled'
                onClick={handleNext}
                large
                fill
              >
                {(typeof nextLabel === 'function'
                  ? nextLabel(isTouched)
                  : nextLabel) || defaultNextLabel}
              </AnchorButton>
            </Popover2>
            <div className='u-float--right u-width__full u-width__auto--lg u-pad__right--xs'>
              {!isEstimate &&
                (hasPendingEdits ? (
                  <Popover2
                    interactionKind={Popover2InteractionKind.CLICK}
                    popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                    disabled={!hasPendingEdits}
                    className='u-width__full'
                    content={
                      <div>
                        <p className='u-push__top--none u-push__bottom--md'>
                          Are you sure you want to discard your changes?
                        </p>

                        <Button className={Classes.POPOVER2_DISMISS}>
                          Keep Editing
                        </Button>

                        <AnchorButton
                          href={`/matches/${match?.id}`}
                          className='warningButton u-float--right'
                        >
                          Discard Changes
                        </AnchorButton>
                      </div>
                    }
                  >
                    <Button large className='u-width__full'>
                      Cancel
                    </Button>
                  </Popover2>
                ) : (
                  <AnchorButton
                    large
                    href={`/matches/${match?.id}`}
                    className='u-width__full u-width__auto--lg'
                  >
                    Cancel
                  </AnchorButton>
                ))}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
