import { Callout, Icon, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { Match } from '../../lib/actions/MatchAction';
type Props = {
  match: Match | null;
};
export default function LiveMatchWarning({ match }: Props) {
  return match && match.state !== 'pending' ? (
    <Callout
      title='Live Match'
      intent={Intent.PRIMARY}
      className='warningCallout u-push__bottom--md'
    >
      You are editing a live Match. Any changes you{' '}
      <strong>make may affect the amount you are charged</strong>, and will be{' '}
      <strong>immediately visible to the driver</strong> on save.
      <Link to={`/matches/${match.id}`} className='calloutLink'>
        View Match
        <Icon icon='chevron-right' />
      </Link>
    </Callout>
  ) : null;
}
