import CancelMatch from './CancelMatch';
import { Link } from 'react-router-dom';
import { Match, MatchState } from '../../lib/actions/MatchAction';
import RateMatch from './RateMatch';
import { useSelector } from 'react-redux';
import { selectUser } from '../../lib/reducers/userSlice';
import {
  canAccessMatch,
  isMatchCancelable,
  isMatchEditable,
  matchInState,
} from '../../lib/Utility';
import { Button } from '@blueprintjs/core';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useState } from 'react';
import DuplicateMatchForm from '../estimate/DuplicateMatchForm';
import { useFeatureFlag } from '../../lib/Hooks';

type Props = {
  match: Match;
};

type Action = 'cancel' | 'duplicate' | 'edit';

export default function MatchActions({ match }: Props) {
  const user = useSelector(selectUser);

  const [action, setAction] = useState<Action>();

  const toggleAction = (targetAction: Action) => {
    setAction(action === targetAction ? undefined : targetAction);
  };

  const canEditLiveMatch = useFeatureFlag('edit_live_match');

  if (!match) return null;
  const isEditable = isMatchEditable(match, user, canEditLiveMatch);
  const canAccess = canAccessMatch(match, user);
  const isCancelable = isMatchCancelable(match);

  const isRatable = matchInState(match, [
    MatchState.Completed,
    MatchState.Charged,
  ]);

  return (
    <>
      {canAccess ? (
        <>
          {isRatable && (
            <div className='reviewBox'>
              <RateMatch match={match} />
            </div>
          )}
          <div className='reviewBox'>
            <h2>Manage Match </h2>

            <Grid>
              <Row className='u-push__bottom--lg'>
                {isEditable && (
                  <Col xs={12} sm={4}>
                    <Button
                      onClick={() => toggleAction('edit')}
                      className='u-width__full'
                      rightIcon='edit'
                      large
                    >
                      Edit
                    </Button>
                  </Col>
                )}
                <Col xs={12} sm={4}>
                  <Button
                    onClick={() => toggleAction('duplicate')}
                    className='u-width__full'
                    rightIcon='duplicate'
                    large
                  >
                    Duplicate
                  </Button>
                </Col>
                {isCancelable && (
                  <Col xs={12} sm={4}>
                    <Button
                      onClick={() => toggleAction('cancel')}
                      className='warningButton u-width__full'
                      rightIcon='disable'
                      large
                    >
                      Cancel
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12}>
                  {action === 'duplicate' && (
                    <>
                      <h3>Duplicate Match #{match.shortcode}</h3>
                      <div className='infoBox'>
                        <DuplicateMatchForm
                          match={match}
                          onDone={() => setAction(undefined)}
                        />
                      </div>
                    </>
                  )}
                  {action === 'cancel' && isCancelable && (
                    <CancelMatch
                      match={match}
                      close={() => setAction(undefined)}
                    />
                  )}
                  {action === 'edit' && isEditable && (
                    <div className='infoBox'>
                      <h3>Edit Match</h3>
                      <p className='u-push__bottom--lg'>
                        Use caution when editing a Match. Any changes you make
                        may affect the amount you are charged for this Match,
                        and will be immediately visible to the driver.
                      </p>
                      <Row end='xs' between='xs'>
                        <Col sm xs={12}>
                          <Button
                            onClick={() => setAction(undefined)}
                            large
                            className='u-push__right--sm'
                          >
                            Back
                          </Button>
                          <Link to={`/ship/${match.id}`}>
                            <Button rightIcon='arrow-right' large>
                              Continue to Edit
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </Grid>
          </div>
        </>
      ) : (
        <div className='reviewBox'>
          {user ? (
            <p>
              This Match was created by another Shipper. Contact creator to
              edit, cancel, or rate this Match
            </p>
          ) : (
            <h2>You are not logged in</h2>
          )}
        </div>
      )}
    </>
  );
}
